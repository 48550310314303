import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { BlogComponent } from './blog/blog.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { EquipoComponent } from './equipo/equipo.component';
import { ClientesComponent } from './clientes/clientes.component';
import { AdministradoresComponent } from './admin/administradores/administradores.component';
import { AdministradorDetalleComponent } from './admin/administrador-detalle/administrador-detalle.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TranslationComponent } from './translation/translation.component';

const routes: Routes = [
  /**
  {
    path: '',
    component: InicioComponent,
    children: [
     {
       path: 'proyectos',
       //loadChildren: './modules/battle/battle.module#BattleModule'
       //loadChildren: './administracion/administracion.module#Proyectos'
       loadChildren: './administracion/administracion.module#AdministracionModule'
     },
     {
      path: 'proyectos/detalle',
      //loadChildren: './modules/battle/battle.module#BattleModule'
      loadChildren: './administracion/administracion.module#AdministracionModule'
    },
     {
       path: 'search',
       loadChildren: './modules/search/search.module#SearchModule'
     }
    ]
  },
  */
 {
  path: '',
  component: InicioComponent,
  /**
  children: [
   {
     path: 'search',
     loadChildren: './modules/search/search.module#SearchModule'
   }
  ]
  */
},
{
  path: 'proyectos',
  //loadChildren: './modules/battle/battle.module#BattleModule'
  //loadChildren: './administracion/administracion.module#Proyectos'
  loadChildren: './administracion/administracion.module#AdministracionModule'
},
/*
{
  path: 'proyectos',
  //loadChildren: './modules/battle/battle.module#BattleModule'
  //loadChildren: './administracion/administracion.module#Proyectos'
  loadChildren: './administracion/administracion.module#AdministracionModule'
},
{
  path: 'proyectos/detalle',
  //loadChildren: './modules/battle/battle.module#BattleModule'
  loadChildren: './administracion/administracion.module#AdministracionModule'
},
*/
  
  {
    path: 'inicio',
    component: InicioComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog/:id',
    component: BlogComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'servicios',
    component: ServiciosComponent,
  },
  /** LAZY LOADING
  {
    path: 'serviciosX',
    component: ServiciosComponent,
    children: [
      {
        path: '',
        redirectTo: "contacto",
        pathMatch: 'full'
      },
      {
        path: 'contacto',
        component: ContactoComponent
      },
      {
        path: 'equipo',
        component: EquipoComponent
      },
      {
        path: '**',
        redirectTo: "contacto",
        pathMatch: 'full'
      }
    ] 
  },
  */
  {
    path: 'equipo',
    component: EquipoComponent
  },
  {
    path: 'clientes',
    component: ClientesComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },
  {
    path: 'translation',
    component: TranslationComponent
  },
  {
    path: 'admin/administradores',
    component: AdministradoresComponent
  },
  {
    path: 'admin/administradores/:id',
    component: AdministradorDetalleComponent
  },
  {  path: '**', redirectTo: 'error' }
  //
  //{  path: '**', component: PageNotFound }
  /*
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'login' }
  */
  /*
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'home-page',
        loadChildren: './pages/home-page/homePage.module#HomePageModule',
        canActivate: [LoginGuard]
      },
      {
        path: 'catalogos',
        loadChildren: './pages/catalogo/catalogo.module#CatalogoModule',
        canActivate: [LoginGuard]
      }
    ]
  }, 
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'login',
      component: LoginComponent
    },
    {
      path: 'register',
      component: RegisterComponent
    },
    {
      path: 'lock',
      component: LockComponent
    }]
  },
  {
    path: 'estandar',
    component: EstandarComponent,
    canActivate: [LoginGuard]
  }, { path: '**', redirectTo: 'login' }
  */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
